import * as actions from '../../actions/actionNames';


const initialState = {
  user: null,
  error: null,
  loader:false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
      };
    case actions.LOGIN_FAILURE:
    case actions.SIGNUP_FAILURE:
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    case actions.SIGN_OUT_SUCCESS:
      return {
        ...state,
        user: null,
        error: null,
      };
    case actions.SIGN_OUT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
