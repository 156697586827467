import { React } from 'react';
import { Link } from "react-router-dom";


export function Li(props) {

    const { text, linkTo, onClose } = props;


  
    return (
        linkTo ? (

            <li>
                <Link to={linkTo} style={{ textDecoration: 'none', alignItems: 'center' }} onClick={onClose} >
                    {text}
                </Link>
            </li>

        ) : (
            <li style={{ textDecoration: 'none', alignItems: 'center' }} onClick={onClose}>
                {text}
            </li>
        )


    )
}