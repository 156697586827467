import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Login_userOrEmail: '',
    Login_password: '',
    Register_username: '',
    Register_email: '',
    Register_password: '',
    Register_confirmPassword: ''
};

const signupFormSlice = createSlice({
    name: 'signupForm',
    initialState,
    reducers: {
        setRegister_email: (state, action) => {
            state.Register_email = action.payload;
        },
        setRegister_password: (state, action) => {
            state.Register_password = action.payload;
        },
        setRegister_confirmPassword: (state, action) => {
            state.Register_confirmPassword = action.payload;
        },
        setRegister_username: (state, action) => {
            state.Register_username = action.payload;
        },
        setLogin_userOrEmail: (state, action) => {
            state.Login_userOrEmail = action.payload;
        },
        setLogin_password: (state, action) => {
            state.Login_password = action.payload;
        },
        resetSignupForm: () => {
            return {
                ...initialState, ...{
                    Login_userOrEmail: '',
                    Login_password: '',
                    Register_username: '',
                    Register_email: '',
                    Register_password: '',
                    Register_confirmPassword: ''
}}
        }
    }
});

export const {
    setRegister_email,
    setRegister_password,
    setRegister_confirmPassword,
    setRegister_username,
    setLogin_userOrEmail,
    setLogin_password,
    resetSignupForm
} = signupFormSlice.actions;

export default signupFormSlice.reducer;
