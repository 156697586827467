import * as actions from '../../actions/actionNames'
const initialState = {
    profile: null,
    error: null,
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_ROFILE_SUCCESS:
        case actions.UPDATE_PROFILE_SECCESS:
            return {
                ...state,
                profile: action.payload,
                error: null,
            };
        case actions.UPDATE_PROFILE_FAILURE:
        case actions.GET_ROFILE_FAILURE:
            return {
                ...state,
                profile: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default profileReducer;
