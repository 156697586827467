import { BaseUrl } from '../../vars/baseUrls';
import * as actions from './actionNames';

export const allUsers = () => {

    return async (dispatch) => {
        dispatch({type:actions.IS_LOADING});
       // setTimeout(async () => {
        try {
            const response = await fetch(BaseUrl + "/profile//getAllUsers", {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                }
            });
            const data = await response.json();

            if(data){
                dispatch({ type: actions.LOADING_IS_DONE });
            }else{
                dispatch({ type: actions.GET_ALL_USERS_SUCCESS, payload: null });
            }

            dispatch({ type: actions.GET_ALL_USERS_SUCCESS, payload: data });
        } catch (error) {
            dispatch({ type: actions.LOADING_IS_DONE });
            dispatch({ type: actions.GET_ALL_USERS_FAILURE, payload: error.message });
        }
       // }, 70000);
    };

}