export const GET_ROFILE_SUCCESS = "GET_ROFILE_SUCCESS";
export const GET_ROFILE_FAILURE = "GET_ROFILE_FAILURE";
export const UPDATE_PROFILE_SECCESS = "UPDATE_PROFILE_SECCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const IS_LOADING = "IS_LOADING";
export const LOADING_IS_DONE = "LOADING_IS_DONE";
