import * as actions from '../../actions/actionNames'


const initialState = {
    isLoading: false,
};

const loading = (state = initialState, action) => {
    switch (action.type) {
        case actions.IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOADING_IS_DONE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
};

export default loading;