import React from "react";

function Card({ title, children }) {
    return (
        <div className="h-100 w-100 d-flex flex-grow-1">
            <div className="card shadow w-100">
                <div className="card-body" style={{ maxHeight: "200px", overflowY: "auto" }}>
                    <h5 className="card-title">{title}</h5>
                    {children}
                </div>
            </div>
        </div>
    );
}

function ItemBox({ children }) {
    return (
        <div
            style={{
                backgroundColor: "#f0f7ff",
                padding: "10px",
                margin: "5px 0",
                borderRadius: "5px",
            }}
        >
            {children}
        </div>
    );
}

export { Card, ItemBox };
