import { combineReducers } from "redux";
import authReducer from "./otherReducers/authReducers";
import profileReducer from "./otherReducers/profileReducer";
import formReducer from "./otherReducers/formReducer";
import allUsers from "./otherReducers/allUsersReducer";
import loader from "./otherReducers/loadingReducer";
import signupFormReducer from './otherReducers/signupFormSlice';


// Import your reducers here
// import counterReducer from './counterReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  allUsers,
  loader,
  profileForm: formReducer,
  signupForm: signupFormReducer
});

export default rootReducer;
