import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Card, ItemBox } from "./sub-components/Card";
import Button from "./sub-components/Button";
import { getProfile } from "../redux/actions/profileActions";
import { useParams } from 'react-router-dom';
import Loader from './sub-components/loader'


function Profile() {
  const profile = useSelector((state) => state.profile.profile);
  const loader = useSelector((state) => state.loader.isLoading);
  const { profileId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile(profileId));
  }, [dispatch, profileId]);



  const handleDeleteProfile = () => {
    console.log("deleted");
  };


  const renderFields = () => {
    return Object.keys(profile).map((field) => (
      field !== "_id" && field !== "profileOwner" && field !== "__v" && field !== "verificationCode" ? (
        <div className="col-sm-6 col-md-4 col-lg-3 my-3" key={field} >
          <Card title={field}>
            {Array.isArray(profile[field]) ? (
              <div>
                {profile[field].map((item, index) => (
                  <ItemBox key={index}>{renderField(item)}</ItemBox>
                ))}
              </div>
            ) : (
              <p>{renderField(profile[field]) || "N/A"}</p>
            )}
          </Card>
        </div >
      ) : (null)
    )
    );
  };

  const renderField = (value) => {
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return value.map((item, index) => (
          <ItemBox key={index}>{renderField(item)}</ItemBox>
        ));
      } else {
        const content = Object.entries(value).map(([key, val], index) => {
          if (key !== "_id" && key !== "profileOwner") {

            return `${val}`;
          }
          return null;

        });
        return <div>{content.filter(item => item !== null).join(" ")}</div>;
      }
    } else {
      return value;
    }
  };


  if (loader) {
    return (
      <div className='h-100'>
        <Loader />
      </div>
    )
    } else 
  return (
    <div className="container-fluid">
      <h1>Welcome to the Profile Page!</h1>

      {profile ? (
        <div>
          <div>
            <div className="row justify-content-between">{renderFields()}</div>
          </div>

          <Link to="/createProfile" className="btn btn-primary">
            Edit Profile
          </Link>

          <Button text="Delete Profile" onClick={handleDeleteProfile} />
        </div>
      ) : (
        <div>
          <p>Profile does not exist.</p>
        </div>
      )}
    </div>
  );
}

export default Profile;
