import { React } from 'react';
import Form from 'react-bootstrap/Form';



function FormField(props) {
    const { placeholder, onchange, val, err, type, tabIndex } = props;



    return (
        <>
            <div class="form-group m-1">
                <Form.Control tabIndex={tabIndex ? tabIndex: null} type={type? type : "text"} placeholder={placeholder} name={placeholder.split(" ").join(" ")} onChange={(e) => onchange(e)} value={val} />
            </div>
            {err && <div className='form-error'>{err}</div> }
        </>

    )

}

export default FormField;