import React from "react";

function Home() {
  return (
    <div>
      <h1>Welcome to the Home Page!</h1>
      
    </div>
  );
}

export default Home;
