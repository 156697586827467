import { persistor } from '../store';
import { BaseUrl } from '../../vars/baseUrls';
import * as actions from './actionNames';

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      const response = await fetch(BaseUrl+"/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailOrUsername: email,
          password: password,
        }),
      });
      const data = await response.json();

      // Dispatch the login success action with the received data
      dispatch({ type: actions.LOGIN_SUCCESS, payload: data });
    } catch (error) {
      // Dispatch the login failure action with the error message
      dispatch({ type: actions.LOGIN_FAILURE, payload: error.message });
    }
  };
};

export const signup = (username, email, password) => {
  return async (dispatch) => {
    try {
      // Perform the signup API request here
      const response = await fetch(
        BaseUrl+"/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            email: email,
            password: password,
          }),
        }
      );
      const data = await response.json();

      // Dispatch the signup success action with the received data
      dispatch({ type: actions.SIGNUP_SUCCESS, payload: data });
    } catch (error) {
      // Dispatch the signup failure action with the error message
      dispatch({ type: actions.SIGNUP_FAILURE, payload: error.message });
    }
  };
};



export const signOut = () => {
  return async (dispatch) => {
    try {
     
      const response = await fetch(BaseUrl + "/auth/signOut", {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        dispatch({ type: actions.SIGN_OUT_SUCCESS });

        await persistor.purge(['auth']);
      } else {
        
        const errorData = await response.json();
        dispatch({ type: actions.SIGN_OUT_FAILURE, payload: errorData.message });
      }
    } catch (error) {
      
      dispatch({ type: actions.SIGN_OUT_FAILURE, payload: error.message });
    }
  };
};