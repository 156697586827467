import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { allUsers } from '../redux/actions/allUsersAction';

import { Link } from 'react-router-dom';
import BlueBox from './sub-components/blueBox'
import Loader from './sub-components/loader'

function AllUsers() {
    const userList = useSelector((state) => state.allUsers.userList);
    const loader = useSelector((state) => state.loader.isLoading);
    

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(allUsers()); 
    }, [dispatch]);

    const renderUsers = () => {
        if (userList.length > 0) {
            return userList.map((user) => (
                <div key={user._id} className="col col-sm-3 col-md-2 col-lg-1 p-1 m-1 bg-light">
                    <Link to={`/profile/${user._id}`} className='text-decoration-none'>
                        <BlueBox text={user.username} />
                    </Link>
                </div>
            ));
        } else {
            return <h1>No User found</h1>;
        }
    };

    if (loader) {
        return (
            <div className='h-100'>
                <Loader />
            </div>
        )
    } else
        return (
            <div>
                <div className='mt-2'>
                    <h1><BlueBox text={"All Users"} border={3} /></h1>
                    {/* <h1 className="text-center blue-border">All Users</h1><br /> */}
                </div>
                <div className='container'>
                    <div className='row no-gutters'>
                        {renderUsers()}
                    </div>
                </div>
            </div>
        );
}

export default AllUsers;
