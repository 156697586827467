import React from "react";

const BlueBox = (prop) => {
    let { text, editable, border } = prop;

    const tickness = border ? `${border}px` : "1px";

    const renderBox = () => {
        if (!editable) {
            return (<div style={{ border: `solid ${tickness} blue` }} className="shadow light-blue" >{text}</div>)
        } else {

        }
    }

    return (
        <>
            {renderBox()}
        </>
    )

}

export default BlueBox;