import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { login, signup } from "../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FormField from './sub-components/FormField'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
    setRegister_email,
    setRegister_password,
    setRegister_confirmPassword,
    setRegister_username,
    setLogin_userOrEmail,
    setLogin_password,
    resetSignupForm
} from '../redux/reducers/otherReducers/signupFormSlice'

function LoginSingnUp() {
    const formData = useSelector((state) => state.signupForm);

    const dispatch = useDispatch();

    const handleRegister_email = (e) => {
        dispatch(setRegister_email(e.target.value));
    };

    const handleRegister_password = (e) => {
        dispatch(setRegister_password(e.target.value));
    };

    const handleRegister_confirmPassword = (e) => {
        dispatch(setRegister_confirmPassword(e.target.value));
    };

    const handleRegister_username = (e) => {
        dispatch(setRegister_username(e.target.value));
    };

    const handleLogin_userOrEmail = (e) => {
        dispatch(setLogin_userOrEmail(e.target.value));
    };

    const handleLogin_password = (e) => {
        dispatch(setLogin_password(e.target.value));
    };

    const navigate = useNavigate();

    const handleLogin = () => {
        dispatch(login(formData.Login_userOrEmail, formData.Login_password));
        dispatch(resetSignupForm());
        navigate("/home");
    };

    const handleSignup = () => {
        dispatch(signup(formData.Register_username, formData.Register_email, formData.Register_password));

        if (formData.Register_password === formData.Register_confirmPassword) {
            dispatch(resetSignupForm());
            navigate("/home");
        }
    };

    // const errorBag = [];


    return (
        <div className="container-fluid">
            <div className="row login-row">
                <div className="col  register d-flex align-items-start justify-content-center">
                    <Form noValidate className="form my-5">
                        <Row>
                            <Form.Group as={Col} md={6} className="mb-2" controlId="formBasicEmail" >
                                <FormField tabIndex={1} placeholder={"Username"} onchange={handleRegister_username} value={formData.Register_username} />
                            </Form.Group>
                            <Form.Group as={Col} md={6} className="mb-2" controlId="formBasicEmail" >
                                <FormField tabIndex={3} placeholder={"Password"} onchange={handleRegister_password} value={formData.Register_password} type={"password"} />
                            </Form.Group>
                            <Form.Group as={Col} md={6} className="mb-2" controlId="formBasicEmail">
                                <FormField tabIndex={2} placeholder={"Email"} onchange={handleRegister_email} value={formData.Register_email} />
                            </Form.Group>

                            <Form.Group as={Col} md={6} className="mb-2" controlId="formBasicEmail">
                                <FormField tabIndex={4} placeholder={"Confirm Password"} onchange={handleRegister_confirmPassword} value={formData.Register_confirmPassword} type={"password"} />
                            </Form.Group>
                        </Row>
                        <div>
                            <Button variant="primary" onClick={handleSignup} className="mt-3">
                                Signup
                            </Button>
                        </div>

                    </Form>
                </div>
                <div className="col  register d-flex align-items-start justify-content-center">
                    <Form noValidate className="form my-5">
                        <Form.Group as={Col} md={12} className="mb-2" controlId="formBasicEmail">
                            <FormField placeholder={"Username Or Email"} onchange={handleLogin_userOrEmail} val={formData.Login_userOrEmail} />
                        </Form.Group>
                        <Form.Group as={Col} md={12} className="mb-2" controlId="formBasicEmail">
                            <FormField placeholder={"Password"} onchange={handleLogin_password} value={formData.Login_password} type={"password"} />
                        </Form.Group>
                        <div>
                            <Button variant="primary" onClick={handleLogin} className="mt-3">
                                Login
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div >
    )





}

export default LoginSingnUp;