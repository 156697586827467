import { BaseUrl } from '../../vars/baseUrls';
import * as actions from './actionNames';


export const createProfile = (name, gender, isPublic) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${BaseUrl}"/profile/editProfile`, {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    gender,
                    isPublic
                }),
            });
            const data = await response.json();

            // Dispatch the login success action with the received data
            dispatch({ type: actions.UPDATE_PROFILE_SECCESS, payload: data.profile });
        } catch (error) {
            console.log("failed");

            // Dispatch the login failure action with the error message
            dispatch({ type: actions.UPDATE_PROFILE_FAILURE, payload: error.message });
        }
    };
};

export const getProfile = (profileId) => {
    return async (dispatch) => {
        dispatch({ type: actions.IS_LOADING });
        try {
            const response = await fetch(`${BaseUrl}/profile/getProfileData/${profileId}`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!response.ok) {
                console.log("no response");
                throw new Error("Network response was not ok");
            }

            const data = await response.json();

            if(data){
                dispatch({ type: actions.LOADING_IS_DONE });
            }

            // Dispatch the login success action with the received data
            dispatch({ type: actions.GET_ROFILE_SUCCESS, payload: data.theProfile });
        } catch (error) {
            console.error("Network error:", error);

            // Dispatch the login failure action with the error message
            dispatch({ type: actions.GET_ROFILE_FAILURE, payload: error.message });
        }
    };
};
