import * as actions from '../../actions/actionNames'


const initialState = {
    userList: [],
    error: null,
};

const allUsers = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                userList: action.payload,
                error: null,
            };
        case actions.GET_ALL_USERS_FAILURE:
            return {
                ...state,
                userList: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

export default allUsers;