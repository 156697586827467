import React from "react";

const Button = ({ text, onClick }) => {
    return (
        <button type="button" className="btn btn-primary m-3 " onClick={onClick}>
            {text}
        </button>
    );
};

export default Button;
