import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';

const Loader = () => {
    const [message, setMessage] = useState("");
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setElapsedTime(prevTime => prevTime + 1);

            if (elapsedTime === 15) {
                setMessage("Sorry it takes longer than usual; we are still working on it.");
            } else if (elapsedTime === 60) {
                setMessage(`Something went wrong,\n
                we will still sending the request to our database,\n
                but you can try again later.`);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [elapsedTime]);

    return (
        <div style={{ marginTop: "25%" }}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            {message && <div style={{ marginTop: "10px" }}>{message}</div>}
        </div>
    )
}

export default Loader;
