import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector } from 'react-redux'
import { useDispatch } from "react-redux";
import { Li } from './sub-components/li_component'
import { signOut } from "../redux/actions/authActions";

const options =
{
    scroll: false,
    backdrop: true,
};

function Sidebar() {
    const [show, setShow] = useState(false);
    const user = useSelector((state) => state.auth.user);


    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    const dispatch = useDispatch();

 
    const handleSignOut = () => {
        dispatch(signOut());
        handleClose()
    }

    return (
        <div className='sidepar'>
            <div className='sidebar_wrapper'>
                <div onClick={toggleShow} className={`arrow ${show ? 'open' : ''}`}>
                    <div /><div /><div />
                </div>
                <Offcanvas show={show} onHide={handleClose} {...options}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Menus</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='container'>
                            <ul className='list-unstyled p-0 m-0 '>
                                <Li text={"Home"} linkTo={"/home"} onClose={handleClose} />
                                <Li text={"Dashboard"} linkTo={"/dashboard"} onClose={handleClose} />
                                <Li text={"allUsers"} linkTo={"/allusers"} onClose={handleClose} />
                            </ul>
                            <div className='row sidebar-footer'>
                                <ul className="align-items-center list-unstyled " onClose={handleClose}>
                                    {(user) ? (

                                        < Li text={"Sign out"} linkTo={"/home"} onClose={handleSignOut} />


                                    ) : (
                                        <>
                                            < Li text={"Login"} linkTo={"/login"} onClose={handleClose} />
                                            <Li text={"Create Account"} linkTo={"/login"} onClose={handleClose} />
                                        </>
                                    )}

                                </ul>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    );
}

export default Sidebar;
//{`'me-2 arrow' ${show ? 'sideBar-open': 'sideBar-close'} `}