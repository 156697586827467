import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store'; // Import your Redux store and persistor
import Home from './components/home';
import Profile from './components/profile';
import Navbar from './components/navbar';
//import ProfileForm from './components/profileForm';
import AllUsers from './components/allUsers';
import SideBarMenu from './components/sideBar'
import LoginSingnUp from './components/Login_SignUp';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className="App">
            <Navbar />
            <SideBarMenu/>
            <Routes>
              <Route exact path='/home' element={<Home />} />
              <Route exact path='/' element={<Home />} />
              <Route exact path="/login" element={<LoginSingnUp />} />
              <Route exact path="/profile/:profileId" element={<Profile />} />
              {/* <Route exact path="/createProfile" element={<ProfileForm />} /> */}
              <Route exact path="/allUsers" element={<AllUsers />} />
            </Routes>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
