import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createFilter from "redux-persist-transform-filter";
import rootReducer from "./reducers/allReducers";

const userFilter = createFilter("auth", ["user"]); 

const persistConfig = {
  key: "root",
  storage, 
  whitelist: ["auth"],
  transforms: [userFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
