import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    contactInformation: [
        { phone: '' },
        { address: '' },
    ],
    biography: '',
    blockedUsers: [],
    dateOfBirth: '',
    emailVerified: true,
    favoriteUsers: [],
    followersOfThisUser: [],
    gender: '',
    isPublic: true,
    makeMeVisibleInSearch: true,
    name: '',
    lastname: '',
    onlyUsersAllowedToSeeMe: [],
    privateFieldsInProfile: [],
    verificationCode: '',
    profilePicture: '',
    socialMediaLinks: [
        { Twitter: '' },
        { Instagram: '' },
        { Facebook: '' },
        { LinkedIn: '' },
        { Telegram: '' },
        { Other: '' },
    ],
    userFollowsThem: [],
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        updateField: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
        },
        updateNestedField: (state, action) => {
            const { path, value } = action.payload;
            const pathArray = path.split('.');
            let currentState = state;
            for (const field of pathArray.slice(0, -1)) {
                currentState = currentState[field];
            }
            currentState[pathArray[pathArray.length - 1]] = value;
        },
        clearFormData: (state) => {
            Object.assign(state, initialState);
        },
    },
});

export const { updateField, updateNestedField, clearFormData } = formSlice.actions;

export default formSlice.reducer;
